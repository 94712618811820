@import './_variables';

.app-layout {
 height: 100%;
 width: 100vw;
 background-color: #eee;
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 position: fixed;
 overflow: auto;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  transition: 0.20s ease-in-out all;
  min-height: 550px;
}
.card-container .card-header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding:0.20rem 1.5rem;
  p{
    color:#777777;
  }
}
.card-container .card-body{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding:0.25rem 1.10rem;
  width: 100%;
  p{
    color:#777777;
  }

}


.split-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #FFFFFF;
  border: 1px solid #ADADAD;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  transition: 0.20s ease-in-out all;
  min-height: 415px;
  flex: 1;
  height: 100%;
}

.split-card-container .col-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}


.split-card-container .col-left{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  width: 60%;
}

.split-card-container .col-right{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;
  height: 100%;
  padding: 2rem;
  background: $color-brand-primary;
  color:white;
  border-radius: 0px 25px 25px 0px;
}

.split-card-container .card-header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding:0rem 1rem;
  h1, h2, p, a{
    color:#777777;
  }

  h1, h2, h3{
    font-weight: 200;
  }
}

.split-card-container .card-body{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding:0.25rem 1.10rem;
  width: 100%;
  p{
    color:#777777;
  }

}


.card-component{
  min-width: 180px;
  min-height: 100px;

  background: #FFFFFF;
  border: 1px solid rgba(153, 153, 153, 0.3);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;

  p{
    margin: 0;
    font-size: 0.85rem;
  }
}
.card-desc{
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px;
}

.card-component .card-avatar{
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
  margin-bottom: 1px;
}

.card-component .card-footer-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-component .card-footer-date{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px 5px
}

.card-component .card-divider{
  position: relative;
  top:2px;
  width: 65px;
  height: 3px;
  background-color: #D9D9D9;
}






.project-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  border: 1px solid #afafaf;
  transition: 0.20s ease-in-out all;
  min-height: 330px;
  min-width: 285px;

}
.project-card-container .card-header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding:0.20rem 1.5rem;
  margin: 0.75rem 0rem;
  color:#777777;
}

.project-card-container .card-image{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.project-card-container .card-body{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:1.25rem 1.1rem;
  width: 100%;
  height: 100%;
  flex:1;
  p{
    color:#777777;
    font-size: 0.85rem;
  }

}

.divider{
  display: block;
  width: 100%;
  height: 2px;
  background: $color-brand-primary;
}

.edit-container{
  transition: all ease-in-out 0.8s;
  border: 1px solid transparent;
  text-decoration: none; /* I added this for clarity of effect */
  border-radius: 15px;
  cursor: pointer;
  padding: 0.5rem;
  &:hover{
    border: 1px solid $color-brand-primary;
  }
}
