h1, h2, h3, h4, h5, h6{
  font-family: 'Inter', Arial, sans-serif;
}
p, a, ul, li{
  font-family: 'Inter', Arial, sans-serif;
}

.white{
  color:#fff;
}
.red{
  color:red !important;
}
