.search-bar{
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 225px;
  height: 35px;
  background: #FFFFFF;
  border: 1px solid #ADADAD;
  border-radius: 25px;
  padding: 10px;
  input{
    border: 0px;
  }
}
