.priority-label{
  width: auto;
  height: auto;
  border: 1px solid #EA0000;
  border-radius: 5px;
  padding:1px 4px;

  p{
    margin: 0px;
    padding:0px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    white-space: nowrap;
  }
}
