@import './_variables';


.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
}

.logo-img {
  height:50px;
  @media (max-width: $xs_max) {
    height:30px;
  }
}
