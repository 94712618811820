@import './_variables';

.header-wrapper{
  background-color: #000;
  color:#fff;
  min-height: 85px;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding:0rem 2rem;
  z-index: 99;
  justify-content: space-between;
  align-items: center;

}

.header-left{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.header-right{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.avatar-flex{
  width: auto;
  display: flex;
  flex-wrap: wrap-reverse;
  max-width: 300px;
  justify-content: center;
  align-items: center;
}


.avatar-name{
  margin: 0rem 1rem;
}

.sidebar{
  background: $color-brand-secondary;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 85px;
  padding: 7rem 0;
  z-index: 98;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.inactive{
  left: -100%;
}

.sidebar .sidebar-link{
    display: block;
    padding: 13px 20px;
    color: rgb(241, 237, 237);
    font-size: 16px;
    position: relative;
    transition: 0.3s ease-in-out all;

    :hover{
      color: #DCE8FF;
    }
}
